<template>
    <styled-interface
        class="dealer-dashboard"
        fixed-container>
        <template #interface-toolbar>
            <dealer-picker-global class="flex md3 xs12" />
        </template>
        <styled-card
            class="mt-5"
            hide-toggle>
            <template #heading>
                Custom Playbooks
            </template>

            <styled-table
                :loading="loading"
                :headers="headers"
                :items="playbooks"
                :rows-per-page="50"
                has-search>
                <template #items="{ item, index }">
                    <tr
                        :class="[
                            (index % 2) ? 'row-even' : 'row-odd'
                        ]">
                        <td>
                            {{ item.display_name }} ({{ item.id }})
                        </td>
                        <td class="text-xs-right">
                            <styled-button
                                :loading="item.id == currentPlaybookLoading"
                                :loader-size="15"
                                small
                                outline
                                @click="onPlaybookSelection(item)">
                                {{ item.deployed ? 'RE-DEPLOY' : 'DEPLOY' }}
                            </styled-button>
                        </td>
                    </tr>
                </template>
            </styled-table>
        </styled-card>
    </styled-interface>
</template>

<script>
import DealerPickerGlobal from '@/components/globals/DealerPickerGlobal';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import StyledButton from '@/components/globals/StyledButton';
import StyledTable from '@/components/globals/StyledTable';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'CustomPlaybooks',
    components: {
        DealerPickerGlobal,
        StyledInterface,
        StyledCard,
        StyledTable,
        StyledButton
    },
    title: 'Custom Playbooks',
    data() {
        return {
            error: '',
            loading: false,
            currentPlaybookLoading: null,
            headers: [
                {
                    text: 'Name',
                    value: 'display_name',
                    align: 'left',
                    sortable: true
                },
                {
                    text: '',
                    width: '240px',
                    sortable: false
                }
            ],
            dealerPlays: [],
            playbooksRaw: [],
            hasInit: false,
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'activeDealerOnboardings'
        ]),
        playbooks() {

            const formatPlaybook = (playbook) => {
                playbook.order = 0;
                playbook.onboarding = false;
                playbook.deployed = false;
                playbook.error = null;

                const existingOnboarding = this.activeDealerOnboardings.find(onboarding => onboarding.play_id === playbook.plays?.[0]?.id);
                if (existingOnboarding) {
                    playbook.onboarding = existingOnboarding.id;
                }

                else if (this.dealerPlays.some(dealerPlay => dealerPlay.play_id === playbook.plays?.[0]?.id)) {
                    playbook.deployed = true;
                    playbook.error = 'Currently Deployed';
                }

                return playbook;
            };

            return this.playbooksRaw.map(formatPlaybook);
        }
    },
    created() {
        this.init();
    },
    activated() {
        // Refresh plays if we've already initialized
        if (this.hasInit) {
            this.getDealerPlays();
        }
    },
    methods: {
        async init() {
            this.loading = true;
            await Promise.all([
                this.getCustomPlaybooks(),
                this.getDealerPlays()
            ]);
            this.loading = false;
            this.hasInit = true;
        },
        async getCustomPlaybooks() {
            try {
                const response = await this.$apiRepository.getCustomPlaybooks();
                this.playbooksRaw = response.data.data;
            } catch (error) {
                console.error('Error loading custom plays', error);
                this.error = 'Error loading custom plays';
            }
        },
        async getDealerPlays() {
            try {
                const response = await this.$apiRepository.getDealerPlays(this.currentDealerId);
                this.dealerPlays = response.data.data;
            } catch (error) {
                console.error('Error loading dealer plays', error);
                this.error = 'Error loading dealer plays';
            }
        },
        onPlaybookSelection(playbook) {

            this.currentPlaybookLoading = playbook.id;

            // If the play has an existing onboarding go to it
            if (playbook.onboarding) {
                this.$router.push({
                    name: 'dealer-onboarding',
                    params: {
                        onboarding_id: playbook.onboarding
                    }
                });
            }
            // Otherwise let's start a new onboarding for the play
            else {
                this.$store.dispatch('startNewOnboarding', {
                    dealerId: this.currentDealerId,
                    productIds: [ playbook.plays?.data?.[0]?.product_id ],
                    playId: playbook.plays?.data?.[0]?.id
                });
            }

            // Reset once the user has navigated away
            setTimeout(() => {
                this.currentPlaybookLoading = null;
            }, 1000);
        }
    }
};
</script>
